
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler
{
    height: 1rem; 
}

.findingGroup
{
    padding-left: 65px; 
   
}

/* .findingTable .p-datatable .p-datatable-thead > tr > th 
{
    background: none; 
} */


/* 
.p-datatable .p-datatable-tbody > tr > td {
    background: auto; 
}


.p-datatable .p-datatable-thead > tr > th {
    background: none;
}  */
/* 
.tableFinding > .p-datatable .p-datatable-tbody > tr {
    background: unset;
} */
.p-datatable-row-expansion 
{
    background-color: aliceblue !important;
} 
/* 
.findingTable .p-datatable .p-datatable-tbody > tr {
    background: aliceblue; 
} */

.findingTable tr
{
    background-color: aliceblue;
}
.findingTable tr:hover
{
    background-color: lightyellow;
}
.findingTable td
{
    vertical-align: top;
}
.findingTable th
{
    color: white;
    background-color: cornflowerblue;
}

.findingTable .p-datatable .p-sortable-column .p-sortable-column-icon {
    display: none;
}

.findingTable .p-datatable .p-sortable-column .p-highlight .p-sortable-column-icon {
    display: inherit;
}

.smallButton
{
    height: 24px; 
    font-weight: normal;
}

.tabItems 
{
    height: 48px;
}

.auditTable .p-tabview .p-tabview-panels {
    margin: 0;
    padding: 0;
}

.auditTable .p-tabview-panel {
    margin: 0;
    padding: 0;
}