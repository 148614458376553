:root {
    --primary-color: #2B2A52;

    /* Conslidated Analytics Color Schema */
    --ca-color-1: #26A3DD;
    --ca-color-2: #72547E;
    --ca-color-3: #12699D;
    --ca-color-4: #4d4d4d;
    --ca-color-5: #999999;
    --ca-color-6: #d8b2c1;
    --ca-color-5-faded: #c2c2c2;
    --ca-color-5-fadeder: #c2c2c26b;

    /* Extended color pallete */
    --tab-color-1: #12699D;
    --tab-color-2: #72547E;
    --tab-color-3: #B485B5;
    --tab-color-4: #1C86BD;
    --tab-color-5: #26A3DD;
    --tab-color-6: #577951;
    --tab-color-7: #8DB686;
    --tab-color-8: #B09C4A;
    --tab-color-9: #D2C968;

    --p-invalid-color: #D32F2F;
}

/* style={{backgroundColor: 'var(--ca-color-1)'}} */
html {
    font-size: 14px;
}

body {
    margin: 0;
    /* overflow: hidden; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

/* Conslidated Analytics Color Schema */
.bg-ca-1 {
    background-color: var(--ca-color-1);
    color: white;
}

.bg-ca-2 {
    background-color: var(--ca-color-2);
    color: white;
}

.bg-ca-3 {
    background-color: var(--ca-color-3);
    color: white;
}

.bg-ca-4 {
    background-color: var(--ca-color-4);
    color: white;
}

.bg-ca-5 {
    background-color: var(--ca-color-5);
    color: white;
}

.bg-ca-6 {
    background-color: var(--ca-color-6);
    color: white;
}

/* Extended colors */

.bg-ca-extended-1 {
    background-color: var(--tab-color-1);
    color: white;
}

.bg-ca-extended-2 {
    background-color: var(--tab-color-2);
    color: white;
}

.bg-ca-extended-3 {
    background-color: var(--tab-color-3);
    color: white;
}

.bg-ca-extended-4 {
    background-color: var(--tab-color-4);
    color: white;
}

.bg-ca-extended-5 {
    background-color: var(--tab-color-5);
    color: white;
}

.bg-ca-extended-6 {
    background-color: var(--tab-color-6);
    color: white;
}

.bg-ca-extended-7 {
    background-color: var(--tab-color-7);
    color: white;
}

/* custom loading spinner */

.loading {
    margin: auto;
    border: 4px solid var(--ca-color-5-fadeder);
    border-radius: 50%;
    border-top: 4px solid var(--ca-color-3);
    width: 40px;
    height: 40px;
    margin: 24.5px 0px;
    animation: spinner 2s linear infinite;
  }
  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* HTML: <div class="loader"></div> */
.loader {
    width: 1rem;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side,#ffffff 90%,#0000);
    background: 
      var(--_g) 0%   50%,
      var(--_g) 50%  50%,
      var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
    display: inline-block;
    margin-bottom: -2px;
  }
  @keyframes l3 {
      20%{background-position:0%   0%, 50%  50%,100%  50%}
      40%{background-position:0% 100%, 50%   0%,100%  50%}
      60%{background-position:0%  50%, 50% 100%,100%   0%}
      80%{background-position:0%  50%, 50%  50%,100% 100%}
  }

.danger {
    color: #EF4444;
}

.danger-background {
    background: #EF4444;
}


/* Prime react override */

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
}

.p-badge {
    background-color: var(--ca-color-1);
    color: white;
}

.helpPanelHeader {
    background-color: var(--ca-color-2);
    color: white
}

.helpPanelHeader > .text-primary {
    color: white !important;
}

.p-panel-header-icon {
    color: white;
}

/* .helpPanel .p-panel .p-panel-header
{
    background-color: #DFDFE5;
} */

.helpPanel .p-panel-content {
    border: none; 

    background: none;
    height: 100%
}

.p-button:not(.p-button-icon, .p-button-icon-only, .pi, .pi-trash, .p-confirm-dialog-reject) {
    background-color: var(--ca-color-1);
    color: white;
}

.p-confirm-dialog-reject {
    color: var(--ca-color-1);
}

/* col-1 pi pi-trash p-button p-component p-button-text p-button-rounded p-button-danger */

.p-badge-danger {
    background-color: var(--p-invalid-color);
    color: white;
}

.badge-zero-value {
    background-color: var(--ca-color-5);
}

.badge-blank {
    background: none;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background-color: var(--ca-color-3)
}

.p-steps-number {
    background-color: var(--ca-color-4);
}

.p-steps ul {
    background-color: var(--ca-color-5-faded);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: var(--ca-color-3)
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: var(--ca-color-3)
}

.p-progressbar {
    background-color: #126a9d67;
}


.p-progressbar .p-progressbar-value {
    background-color: var(--ca-color-3);
}