.body .p-4 {
    background-color: rgba(0, 0, 0, 0.3);
}

.mask {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1101;
    background-color: var(--maskbg);
}

.mask-content {
    width: 60vw;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    max-height: 90%;
    transform: scale(1);
    position: relative;
}