.dataRowError {
    background-color: lightcoral;
    color: white; 
}
.dataRowExist {
    background-color: rgb(255, 255, 177);
}
.dataRowIgnored
{
    color: #c5c4c2;
    font-style: italic;
}

.p-fieldset .p-fieldset-legend {
    padding: 8px; 
}

.statPopInput
{
    padding: 0px; 
    text-align: right;
}

.statPopInput input
{
    text-align: right;
}
/* 
.p-ignore-ui .p-checkbox-box
{
    background-color: white;
} */
/* 
.p-ignore-ui .p-checkbox-icon
{
    color: gray
} */
.statLabel
{
    line-height: 40px;
}
.statInput
{
    float: right; 
}

.statInput .p-inputnumber-input {
    width: 80px;
    text-align: right;
    
}

.panel-width {
    margin: auto; 
    max-width: 1280px; 
}
/* 
.helpPanel {
    border: 1px solid silver;   
    height: 100%;    
    border-radius: 6px;
    background-image: url('../assets/images/random_bg.jpg') ;
} */

/* .p-paginator button { */
    /* color: white;  */
/* } */

/* Fixes pagination number alignment */
.p-paginator .p-dropdown {
    height: fit-content;
}

/* 
.p-steps .p-steps-item .p-menuitem-link {
    flex-direction: column;
} */

.p-confirm-dialog {
    max-width: 40rem;
}

.p-steps {
    padding: 0px; 
}
.banner {
    background-color: #f5f7f8;
    width: 100%
}

.p-slider.p-slider-horizontal {
    height: 5px;
}

.ruleField {
    font-weight: bold;
}

.ruleValue {
    font-weight: bold;
    color: var(--ca-color-2);     
}

.loan-report-table 
{
    border: 1 px solid gray;
    border-collapse: collapse;
    padding: 1rem; 
    width: 100%
}
.loan-report-table td
{
    border: 1px solid gray; 
    padding: 3px; 
}