
/* Expand Cell Height so it doesn't truncate cell content  */
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    text-wrap: wrap;
}


.clauseLabel
{
  color: purple; 
  margin-left: 3px;
}

.linkButton
{
  font-size: 14px;
  color: #009ada;
}
.linkButton:hover
{
  color: var(--primary-color);
  font-weight: bold;
}
.firstGroup {
  padding: 5px;
  margin-bottom: 5px;
}

.secondGroup {
  background-color: #ddd;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}


.p-dropdown-panel .p-dropdown-items .p-dropdown-item
{
  padding: 5px !important; 
}

.p-multiselect .p-multiselect-label {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-dropdown-trigger
{
  justify-content: right !important; 
}

.p-dropdown .p-dropdown-trigger {
  width: 12px !important;
}

.p-inputtext {
  padding: 5px;
}

.p-dropdown-padding 
{
    padding: 0px; 
}

.p-dropdown-label
{
  padding-top: 5px;
  padding-bottom: 5px;
}
/* .p-inputtext
{
  font-size: 14px !important; 
} */


.p-radiobutton .p-radiobutton-box
{
  width: 16px !important; 
  height: 16px !important; 
}

.p-radiobutton
{
  width: 16px !important; 
  height: 16px !important; 
  vertical-align: middle !important;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 8px !important;
  height: 8px !important;
}

.p-autocomplete 
{
    height: 30px; 
}

.p-dropdown .p-dropdown-trigger {
  
  margin-right: 3px;
 
}



.p-panel .p-panel-header .p-panel-header-icon {
    width: 25px;
    height: 25px;
}

.highlightMatch 
{
  font-weight:bold;
  background-color: yellow;
}

.p-orderlist-list {
  height: 585px;
  min-height: 585px;
}


.p-orderlist .p-orderlist-list .p-orderlist-item 
{
  padding: 5px; 
}