.linkButton
{
  font-size: 14px;
  color: var(--ca-color-1);
}
.linkButton:hover
{
  color: var(--primary-color);
  font-weight: bold;
}

.tab-color-yellow
{
  background-color: #F0F1D4;  
}

.tab-color-green
{
  background-color: var(--ca-color-2);
  color: white; 
}

.p-accordion .p-accordion-tab { 
  margin-bottom: 5px;
}

.p-inputswitch-slider
{
  background-color: white;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: var(--ca-color-1);
}

.loan-form .form {
  display: flex;
  margin-top: 20px;
}

.loan-form .form-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 20px;
}

.loan-form .form-column:last-child {
  margin-right: 0;
}

.loan-form .form-column > span {
  margin-bottom: 10px;
  width: 100%;
}

.loan-form .form-column .p-dropdown {
    padding: 8px;
    margin: .5px 0px 1.5px;
    border: 1px solid #ccc;
}

.loan-form .p-dropdown-label {
  color: rgba(0, 0, 0, 0.87);
}

.loan-form .form-column .p-label label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  padding-left: 15px;
}

.audit-plan textarea {
  border-color: #e0e0e0;
}

.loan-form .form-column > span > input {
  border: none;
  border-bottom: 1px solid #ccc;
  /* border-radius: 0; */
  width: 100%;
  padding: 1rem; 
}

.loan-form .form-column > span > input:focus {
  border: none;
  border-bottom: 1px solid #ccc;
}

.loan-form { 
  margin-top: 16px;
}

.checklist-set 
{
    margin-left: 0px;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 1rem;
}

.tabSpinner 
{
  width: 16px;
  height: 16px;
  vertical-align: middle;

}
.tabSpinner .p-progress-spinner-circle 
{
   stroke: white; 
   animation: none; 
}


.badge-zero-value
{
  background-color: var(--ca-color-5);
}

.button-small 
{
  height: 24px;
  font-size: small;
}

.note-display 
{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.val-comments img
{
  max-width: 100%;
}

.header-label
{
  font-weight: bold;

}

/* .p-invalid > .p-editor-container .p-editor-content.ql-snow {
  border: 1px solid var(--p-invalid-color) !important;
} */

.p-invalid > .ql-container.ql-snow {
  border: 1px solid var(--p-invalid-color) !important;
}

/* .p-invalid {
  border: 1px solid var(--p-invalid-color) !important;
} */