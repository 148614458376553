.dashboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.dashboard-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 1rem;
    /* cursor: pointer; */
}

.dashboard-item > a {
    text-decoration: none;
    color: #000;
}

.dashboard-item > a:hover {
    text-decoration: underline;
}

.dashboard-item > p {
    font-size: 2rem;
    font-weight: bold;
}