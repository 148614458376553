.account-form .form {
    display: flex;
    margin-top: 20px;
}

.account-form .form-column {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 20px;
}

.account-form .form-column:last-child {
    margin-right: 0;
}

.account-form .form-column > span {
    margin-bottom: 10px;
    width: 100%;
}

.account-form .form-column .p-dropdown {
    padding: 8px;
}

.account-form .p-dropdown-label {
  color: rgba(0, 0, 0, 0.87);
}

.account-form .form-column .p-label label {
  /* color: rgba(0, 0, 0, 0.6); */
  font-size: 12px;
  padding-left: 15px;
}

.account-form .form-column > span > input {
    border: none;
    border-bottom: 1px solid #ccc;
    /* border-radius: 0; */
    width: 100%;
    padding: 15px;
}

.account-form .form-column > span > input:focus {
    border: none;
    border-bottom: 1px solid #ccc;
}

.account-form .form-column > span > label {
    color: var(--ca-color-4);
}

.account-form .form-column > span > input.p-invalid {
    border: none;
    border-bottom: 1px solid #b00020;
    /* border-radius: 0; */
    width: 100%;
}

.account-form .form-column > span > input.p-invalid:focus {
    border: none;
    border-bottom: 1px solid #b00020;
}
