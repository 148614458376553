.nav .p-menubar {
    background-color: var(--primary-color);
    border: none;
    border-radius: 0;
    padding: 0.5rem;
    position: relative;
    z-index: 100 !important;
    height: 36px; 
}

.nav .p-menuitem-text,
.nav .p-submenu-icon {
    color: #fff;
}

.nav .p-submenu-list .p-menuitem-text {
    color: #000;
}

.header svg, .header ul, .header li {
    display: none !important;
}

@media screen and (max-width: 960px) {
    .p-menubar .p-menubar-button {
        color: white;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: var(--primary-color);
        font-weight: bold;
        font-size: large;
    }
    .nav .p-submenu-list .p-menuitem-text {
        color: var(--primary-color);
        font-weight: normal;
        font-size: medium;
        margin-left: 1rem;
    }

    .nav .p-menuitem-text, .nav .p-submenu-icon {
        color: var(--primary-color);
    }
}

