.user-form .form {
    display: flex;
    margin-top: 20px;
}

.user-form .form-column {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 20px;
}

.user-form .form-column:last-child {
    margin-right: 0;
}

.user-form .form-column > span {
    margin-bottom: 10px;
    width: 100%;
}

.user-form .form-column .p-dropdown {
    padding: 10px;
}

.user-form .form-column > span > input.p-invalid {
    border: none;
    border-bottom: 1px solid #b00020;
    /* border-radius: 0; */
    width: 100%;
}

.user-form .form-column > span > input {
    border: none;
    border-bottom: 1px solid #ccc;
    /* border-radius: 0; */
    width: 100%;
    padding: 15px; 
}

#azure-id-help {
    color: var(--ca-color-4);
}
.user-form .form-column > span > label {
    color: var(--ca-color-4);
}

.user-form .form-column > span > input.p-invalid:focus {
    border: none;
    border-bottom: 1px solid #b00020;
    /* border-radius: 0; */
    width: 100%;
}

.user-form .form-column > span > input:focus {
    border: none;
    border-bottom: 1px solid #ccc;
}
